<template>
  <div>
    <div class="page dashboard">
      <div class="dashboard__lead">
      	<div class="container">
      		<div class="dashboard__main--header">
		      	<h1>View Lead</h1>
		      	<router-link :to="{ name: 'dashboard' }">
          <button class="btn"><i class="fad fa-arrow-left fa-2x"></i></button>
        </router-link>
		      </div>
	      	<hr>
	      	<transition name="fadeStop">
	          <Loader v-if="!leadInfo" />
	        </transition>

	        <div v-if="leadInfo">
	        	<div class="flex justify-space-between align-center mb-3">
	        		<h3 class="mb-0">{{leadInfo.name}}</h3>
	        		<select v-model="leadInfo.status" @change="editLead()" class="select-css">
										  <option v-for="option in statuses">
										    {{ option }}
										  </option>
										</select>
	        	</div>

	        	<table>
	        		<tbody>
	        			<tr>
	        				<td>Submitted Date:</td>
	        				<td>{{realDate | moment("MMM Do YYYY")}}</td>
	        			</tr>
	        			<tr>
	        				<td>DOB:</td>
	        				<td>{{leadInfo.dob}}</td>
	        			</tr>
	        			<tr>
	        				<td>Email:</td>
	        				<td>{{leadInfo.email}}</td>
	        			</tr>
	        			<tr>
	        				<td>Phone:</td>
	        				<td>{{leadInfo.phone}}</td>
	        			</tr>
	        			<tr>
	        				<td>First Tattoo?</td>
	        				<td>{{leadInfo.first}}</td>
	        			</tr>
	        			<tr>
	        				<td>Tattoo Size:</td>
	        				<td>{{leadInfo.size}}</td>
	        			</tr>
	        			<tr>
	        				<td>Tattoo Description:</td>
	        				<td>{{leadInfo.description}}</td>
	        			</tr>
	        			<tr>
	        				<td>How did they hear about you:</td>
	        				<td>{{leadInfo.how}}</td>
	        			</tr>
	        		</tbody>
	        	</table>
	        	<div v-if="leadInfo.picUrl" class="mb-5 mt-5">
	        		<img :src="leadInfo.picUrl" alt="" class="tatpic">
	        	</div>
	        </div>
				</div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Footer from '@/components/Footer.vue'
import Loader from '@/components/Loader.vue'
const fb = require('../../firebaseConfig.js')

export default {
  name: 'lead',
  data() {
    return {
    	statuses: [
    		'New',
    		'Cancelled',
    		'Appointment Set',
    		'Appointment Complete'
    	]
    }
  },
  created () {
    console.log(this.$route.params.id)
    this.$store.dispatch("getLeadFromId", this.$route.params.id);
  },
  computed: {
    ...mapState(['currentUser', 'leadInfo']),
    realDate() {
    	if (this.leadInfo && this.leadInfo.created) {
	      return new Date(this.leadInfo.created.seconds * 1000)
	    } else {

	    }
    }
  },
  components: {
    Footer,
    Loader
  },
  methods: {
    editLead() {
      this.performingRequest = true
      let {lead} = this.leadInfo
      fb.leadsCollection.doc(this.leadInfo.id).update(this.leadInfo)
      setTimeout(() => {
        this.performingRequest = false
      }, 500)
      
    },
    cancel() {
      let url = "/dashboard"
      this.$router.push(url)
    }
  },
  beforeDestroy(){
    this.$store.dispatch("clearLeadState")
  }
}
</script>