<template>
	<div class="footer">
		<div class="footer__body">
			<div class="text-center">
	      <img alt="Cactus Plant Studio" src="../assets/fav.png" class="cactus">
	    </div>
			<router-link tag="button" class="btn btn__flat" to="/">Home</router-link> // <router-link tag="button" class="btn btn__flat" :to="{name: 'dashboard'}">Admin</router-link><span v-if="user"> // <button class="btn btn__flat" @click="logout()">Logout</button></span>
			<p class="caption">® Cactus Plant Studio, 2021</p>
		</div>
	</div>
</template>

<script>
export default {
	props: ['user'],
	methods: {
    logout() {
      this.$store.dispatch('logout')
    }
  },
}
</script>